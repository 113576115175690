import Projects from './Projects';
import Contact from './Contact';
import { useEffect } from 'react';
import gsap from 'gsap';
import andy from '../andy.jpeg';

const About = () => {
  useEffect(() => {
    gsap.from('.bio', { x: 1000, duration: 2 });
    gsap.from('.bioParagraph', { x: 1000, duration: 2 });
    gsap.from('.profilePic', { x: -1000, duration: 2, rotate: 360 });
  }, []);
  return (
    <div>
      <div className="about">
        <div className="avatar">
          <img src={andy} alt="Andy" className="profilePic" />
        </div>
        <div className="bio">
          <h1 className="heading">Andy Parks</h1>
          <h2 className="heading">Junior Software Developer</h2>
        </div>
        <div className="bioParagraph">
          <p>
            Recent graduate of the Nothcoders bootcamp, excited to continue the
            steep learning curve I am on and trying to land my first role in
            tech. <br></br>
            <br></br> Passionate about working on and developing software that
            can have a meaningful impact on peoples lives.
          </p>
        </div>
      </div>
      <Projects />
      <Contact />
    </div>
  );
};

export default About;
