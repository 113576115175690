const projects = [
  {
    name: 'Northcoders News Backend',
    description: 'A news article API built using Express, Nodejs and PSQL.',
    tags: ['javascript', 'backend', 'node', 'PSQL', 'express', 'jest'],
    hosted: 'https://nc-news-k43r.onrender.com/api',
    repo: 'https://github.com/andmikpar/ap-nc-news',
  },
  {
    name: 'Northcoders News Frontend',
    description:
      ' Northcoders news is a React application in which users can read and interact with news articles.',
    tags: ['javascript', 'react', 'frontend', 'html', 'css'],
    hosted: 'https://ap-nc-news.netlify.app/',
    repo: 'https://github.com/andmikpar/fe-nc-news',
  },
  {
    name: 'Stadium Hangman',
    description:
      '  Stadium Hangman is a React app which users try to guess the name of a sports stadium from the sport which they select.',
    tags: ['javascript', 'frontend', 'react', 'html', 'css'],
    hosted: 'https://main--aparksreacthangman.netlify.app/',
    repo: 'https://github.com/andmikpar/react-hangman',
  },
];

export default projects;
