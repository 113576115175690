import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { BiMailSend } from 'react-icons/bi';
import { Stack } from '@mui/system';

const Contact = () => {
  const [input, setInput] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [toSend, setToSend] = useState({});

  function checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else return false;
  }

  function handleChange(e) {
    e.preventDefault();
    if (e.target.name === 'input') {
      setInput(e.target.value);
      setToSend([name, email, input]);
    }
    if (e.target.name === 'name') {
      setName(e.target.value);
      setToSend([name, email, input]);
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value);
      setToSend([name, email, input]);
    }
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },

      body: encode({
        'form-name': 'contact',
        name: name,
        input: input,
        email: email,
      }),
    })
      .then(() => {
        alert('Success!');
        setEmail('');
        setInput('');
        setName('');
      })
      .catch((error) => alert(error));

    e.preventDefault();
  };
  return (
    <div className="contact">
      <h1>Get In Touch</h1>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        className="form"
        style={{
          'max-height': '100%',
          maxWidth: '100vw',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
        onSubmit={(e) => handleSubmit(e)}
      >
        <input type={'hidden'} name="form-name" value={'contact'}></input>
        <Stack spacing={1}>
          <div className="firstBox">
            <TextField
              className="formBox"
              sx={{
                input: { color: '#001e1d', fontSize: '15px' },
              }}
              name={'name'}
              value={name}
              label="Name"
              required
              style={{ width: '40vh' }}
              variant="outlined"
              size="large"
              onChange={(e) => {
                handleChange(e);
              }}
            ></TextField>
          </div>
          <div>
            <TextField
              sx={{
                input: { color: '#001e1d' },
                fontSize: '15px',
                height: '3vw',
              }}
              name={'email'}
              className="formBox"
              value={email}
              label="Email Address"
              required
              variant="outlined"
              size="large"
              style={{ width: '40vh' }}
              error={!checkEmail(email) && email.length !== 0}
              onChange={(e) => {
                handleChange(e);
              }}
            ></TextField>
          </div>
          <br></br>
          <br></br>
          <div className="secondBox">
            <TextField
              sx={{ input: { color: '#001e1d' } }}
              className="formBox"
              name={'input'}
              value={input}
              style={{ width: '40vh' }}
              label="Enter message here"
              required
              multiline
              rows={4}
              variant="outlined"
              size="large"
              onChange={(e) => {
                handleChange(e);
              }}
            ></TextField>
          </div>
          <div className="thirdBox">
            <Button
              className="contactButton"
              variant="contained"
              startIcon={<BiMailSend />}
              size="large"
              style={{
                'margin-top': '3vh',
                width: '30vh',
                height: '10vw',
                'max-height': '45px',
              }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Send
            </Button>
          </div>
        </Stack>
      </form>
    </div>
  );
};

export default Contact;
