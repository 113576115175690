import { Link } from 'react-router-dom';
const NavBar = () => {
  return (
    <div className="Navbar">
      <Link to="/" className="nav">
        About
      </Link>
      <a href="#projects" className="nav">
        Projects
      </a>
      <a href="#contact" className="nav">
        Contact
      </a>
    </div>
  );
};

export default NavBar;
