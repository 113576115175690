import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <a
        href="https://github.com/andmikpar"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <BsGithub className="icon" />
      </a>
      <a
        href="https://www.linkedin.com/in/andy-parks-531a2a260/"
        target="_blank"
        rel="noreferrer"
        className="social"
      >
        <BsLinkedin />
      </a>
      <a href="#contact" className="social">
        Contact
      </a>
    </div>
  );
};

export default Footer;
