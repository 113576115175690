import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import projects from '../projects';

gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
  const [projectsArray, setProjectsArray] = useState([...projects]);

  const [techs, setTechs] = useState(null);
  const handleTechsChange = (_event, updatedTechs) => {
    setTechs(updatedTechs || null);
  };

  const ref = useRef(null);

  useEffect(() => {
    const card = ref.current;
    gsap.from(
      '.projectCard',

      {
        x: 1995,
        duration: 1.55,
        scrollTrigger: {
          trigger: card,
        },
      }
    );
  }, [ref]);

  useEffect(() => {
    const filteredProjects = [];

    projects.forEach((project) => {
      console.log(techs, project.tags);
      if (project.tags.includes(techs)) {
        filteredProjects.push(project);
      }
    });
    if (filteredProjects.length !== 0) {
      setProjectsArray([...filteredProjects]);
    } else setProjectsArray([...projects]);
  }, [techs]);

  return (
    <div className="projects">
      <h1 className="projectHead">Projects</h1>
      <ToggleButtonGroup
        className="toggles"
        aria-label="Tech Options"
        value={techs}
        onChange={handleTechsChange}
        size="large"
        color="success"
        exclusive
      >
        <ToggleButton
          sx={{ color: '#ffffff' }}
          value={'frontend'}
          aria-label="frontend"
        >
          Frontend
        </ToggleButton>
        <ToggleButton
          sx={{ color: '#ffffff' }}
          value={'backend'}
          aria-label="backend"
        >
          Backend
        </ToggleButton>
      </ToggleButtonGroup>

      <div className="projectCards" ref={ref}>
        {projectsArray.map((project) => {
          return (
            <div className="projectCard" ref={ref}>
              <div className="projectContainer">
                <div className="front">
                  <h2>{project.name}</h2>
                  <p>{project.description}</p>
                </div>
                <div className="back">
                  <button className="projectButton">
                    <a
                      href={project.hosted}
                      target="_blank"
                      className="projectLink"
                    >
                      View Hosted
                    </a>
                  </button>

                  <button className="projectButton">
                    <a
                      href={project.repo}
                      target="_blank"
                      className="projectLink"
                    >
                      View Repo
                    </a>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
